<template>
  <div class="printing">
    <h1>Archival Pigment Printing</h1>
    <h4>Are you looking for prints that will stand the test of time?</h4>
    <p>Archival pigment printing, sometimes referred to as gicleé, is an advanced form of inkjet printing, which utilizes a large color gamut of highly pigmented archival inks, which in conjunction with being printed onto archival media, and being properly cared for, result in a high quality digital print that will stand the test of time. As of today, archival pigment printing is the premier digital process for high quality fine art printing, be that reproductions of traditional painting, digital art, photography or any other two-dimensional medium.</p>
    <br/>
    <details>
      <summary style="font-weight: 700; text-transform: uppercase; letter-spacing: 1px; opacity: 0.8; font-size: 1.0em;">
        Stretched Canvas
      </summary>
      <br/>
      <p>
        Our archival canvas is from Epson’s Exhibition line, and comes in either a matte, satin or glossy finish. This canvas is able to be displayed without UV-protective glass as there is UV protection in the surface coating. This makes canvas a great option to display your work without the necessity of framing. We stretch the canvas onto either ⅝” or 1.5” strainer stock and finish them with a hanging wire.
      </p>
      <br/>
      <p>
        Canvas stretched onto 1.5” strainer looks great in a ‘canvas floater’ frame, but it is often displayed with no further framing. Instead we can print either a solid color, overflow or mirror of the image on the sides to give a more finished look without framing. Whereas the 5/8” stretched canvas is often displayed within a frame, albeit without glass, as it’s final presentation, since the shallower profile will fit within standard frame moulding.
      </p>
      <br/>
      <p>
        We also stock metallic canvas on special request, which in certain applications, such as high contrast or highly saturated works, can give the piece a unique and bold character.
      </p>
      <br/>
        <details>
        <summary style="font-weight: 700; opacity: 0.85; text-decoration: underline; text-decoration-color: rgba(242, 157, 75, 0.50); text-underline-offset: 2px;">
          Stretched Canvas Pricelist
        </summary>
        <br/>
        <p>
        <strong>
        Quantity discounts for printing only apply to the same image, on one paper, being printed at the same size in the same order.
        </strong>
        </p>
        <p>
        For custom sizes, round up to nearest square inch.
        </p>
        <br/>
          <div>
            <table>
              <tr>
                <th>Sq. In.</th>
                <th>Std. Size</th>
                <th>
                  <select class="form-select" v-model="chooseOption">
                    <option value="" disabled selected>Quantity</option>        
                    <option value="1">1</option>
                    <option value="2">2+</option>
                  </select>
                </th>
              </tr>
              <tr>
                <td class="sqin">80</td>
                <td>8x10</td>
                <td v-if="chooseOption === '1'">$61</td>
                <td v-if="chooseOption === '2'">$36</td>
              </tr>
              <tr>
                <td class="sqin">108</td>
                <td>9x12</td>
                <td v-if="chooseOption === '1'">$64</td>
                <td v-if="chooseOption === '2'">$38</td>
              </tr>
              <tr>
                <td class="sqin">144</td>
                <td>12x12</td>
                <td v-if="chooseOption === '1'">$75</td>
                <td v-if="chooseOption === '2'">$45</td>
              </tr>
              <tr>
                <td class="sqin">154</td>
                <td>11x14</td>
                <td v-if="chooseOption === '1'">$77</td>
                <td v-if="chooseOption === '2'">$46</td>
              </tr>
              <tr>
                <td class="sqin">192</td>
                <td>12x16</td>
                <td v-if="chooseOption === '1'">$90</td>
                <td v-if="chooseOption === '2'">$54</td>
              </tr>
              <tr>
                <td class="sqin">216</td>
                <td>12x18</td>
                <td v-if="chooseOption === '1'">$92</td>
                <td v-if="chooseOption === '2'">$55</td>
              </tr>
              <tr>
                <td class="sqin">252</td>
                <td>14x18</td>
                <td v-if="chooseOption === '1'">$93</td>
                <td v-if="chooseOption === '2'">$56</td>
              </tr>
              <tr>
                <td class="sqin">320</td>
                <td>16x20</td>
                <td v-if="chooseOption === '1'">$96</td>
                <td v-if="chooseOption === '2'">$58</td>
              </tr>
              <tr>
                <td class="sqin">384</td>
                <td>16x24</td>
                <td v-if="chooseOption === '1'">$115</td>
                <td v-if="chooseOption === '2'">$69</td>
              </tr>
              <tr>
                <td class="sqin">432</td>
                <td>18x24</td>
                <td v-if="chooseOption === '1'">$124</td>
                <td v-if="chooseOption === '2'">$74</td>
              </tr>
              <tr>
                <td class="sqin">480</td>
                <td>20x24</td>
                <td v-if="chooseOption === '1'">$134</td>
                <td v-if="chooseOption === '2'">$94</td>
              </tr>
              <tr>
                <td class="sqin">576</td>
                <td>24x24</td>
                <td v-if="chooseOption === '1'">$146</td>
                <td v-if="chooseOption === '2'">$102</td>
              </tr>
              <tr>
                <td class="sqin">600</td>
                <td>20x30</td>
                <td v-if="chooseOption === '1'">$152</td>
                <td v-if="chooseOption === '2'">$107</td>
              </tr>
              <tr>
                <td class="sqin">704</td>
                <td>22x32</td>
                <td v-if="chooseOption === '1'">$163</td>
                <td v-if="chooseOption === '2'">$114</td>
              </tr>
              <tr>
                <td class="sqin">720</td>
                <td>24x30</td>
                <td v-if="chooseOption === '1'">$166</td>
                <td v-if="chooseOption === '2'">$116</td>
              </tr>
              <tr>
                <td class="sqin">864</td>
                <td>24x36</td>
                <td v-if="chooseOption === '1'">$204</td>
                <td v-if="chooseOption === '2'">$153</td>
              </tr>
              <tr>
                <td class="sqin">1200</td>
                <td>30x40</td>
                <td v-if="chooseOption === '1'">$229</td>
                <td v-if="chooseOption === '2'">$181</td>
              </tr>
              <tr>
                <td class="sqin">1296</td>
                <td>36x36</td>
                <td v-if="chooseOption === '1'">$247</td>
                <td v-if="chooseOption === '2'">$222</td>
              </tr>
              <tr>
                <td class="sqin">1440</td>
                <td>30x48</td>
                <td v-if="chooseOption === '1'">$263</td>
                <td v-if="chooseOption === '2'">$236</td>
              </tr>
              <tr>
                <td class="sqin">1536</td>
                <td>32x48</td>
                <td v-if="chooseOption === '1'">$277</td>
                <td v-if="chooseOption === '2'">$249</td>
              </tr>
              <tr>
                <td class="sqin">1728</td>
                <td>36x48</td>
                <td v-if="chooseOption === '1'">$303</td>
                <td v-if="chooseOption === '2'">$273</td>
              </tr>
              <tr>
                <td class="sqin">1944</td>
                <td>36x54</td>
                <td v-if="chooseOption === '1'">$320</td>
                <td v-if="chooseOption === '2'">$288</td>
              </tr>
              <tr>
                <td class="sqin">2400</td>
                <td>40x60</td>
                <td v-if="chooseOption === '1'">$356</td>
                <td v-if="chooseOption === '2'">$320</td>
              </tr>
              <tr>
                <td class="sqin">2880</td>
                <td>40x72</td>
                <td v-if="chooseOption === '1'">$427</td>
                <td v-if="chooseOption === '2'">$384</td>
              </tr>
              <tr>
                <td class="sqin">3456</td>
                <td>48x72</td>
                <td v-if="chooseOption === '1'">$462</td>
                <td v-if="chooseOption === '2'">$416</td>
              </tr>
              <tr>
                <td class="sqin">4374</td>
                <td>54x81</td>
                <td v-if="chooseOption === '1'">$545</td>
                <td v-if="chooseOption === '2'">$490</td>
              </tr>
              <tr>
                <td class="sqin">5400</td>
                <td>54x100</td>
                <td v-if="chooseOption === '1'">$673</td>
                <td v-if="chooseOption === '2'">$606</td>
              </tr>
            </table>
          </div>
        </details>
          <br>
    </details>
    <br/>
    <details>
      <summary style="font-weight: 700; text-transform: uppercase; letter-spacing: 1px; opacity: 0.8; font-size: 1.0em;">
        Premium Fine Art
      </summary>
      <br/>
      <p>
        Our premium fine art papers come in two variants from Epson, much like watercolor rag, either a hot pressed or cold pressed variant, which gives the surface either a smooth or textured surface. 
      </p>
      <br/>
      <p>
        These papers are acid-free 100% cotton rag, meeting archival standards. The coating allows the ink to stay on the surface of the paper, rather than sink in, as a traditional fine art paper. Yet the papers very much have the characteristic of traditional fine art papers in feel and weight, which is around 340gsm. They have a brighter white point than traditional papers, as this allows bright white pigments to be reproduced since we cannot print with white ink.
      </p>
      <br/>
      <p>
        The papers require UV-protection for longevity from fading, as well as care in handling, especially for darker planes of color, as the inks to reproduce these colors are highly pigmented, and therefore prone to scuffing. While these papers are meant to as closely as possible mimic traditional fine art papers, reproducing pieces that were originally on another substrate also look great on these papers, as well as for high-end photographic work.      </p>
      <br/>
        <details>
        <summary style="font-weight: 700; opacity: 0.85; text-decoration: underline; text-decoration-color: rgba(242, 157, 75, 0.50); text-underline-offset: 2px;">
          Premium Fine Art Pricelist
        </summary>
        <br/>
        <p>
        <strong>
        Quantity discounts for printing only apply to the same image, on one paper, being printed at the same size in the same order.
        </strong>
        </p>
        <p>
        For custom sizes, round up to nearest square inch.
        </p>
        <br/>
          <div>
            <table>
              <tr>
                <th>Sq. In.</th>
                <th>Std. Size</th>
                <th>
                  <select class="form-select" v-model="chooseOption">
                    <option value="" disabled selected>Quantity</option>        
                    <option value="1">1</option>
                    <option value="2">2-9</option>
                    <option value="3">10-19</option>
                    <option value="4">20-49</option>
                    <option value="5">50-99</option>
                    <option value="6">100+</option>
                  </select>
                </th>
              </tr>
              <tr>
                <td class="sqin">12</td>
                <td>3x4</td>
                <td v-if="chooseOption === '1'">$4.33</td>
                <td v-if="chooseOption === '2'">$3.46</td>
                <td v-if="chooseOption === '3'">$2.60</td>
                <td v-if="chooseOption === '4'">$2.16</td>
                <td v-if="chooseOption === '5'">$1.73</td>
                <td v-if="chooseOption === '6'">$1.51</td>
              </tr>
              <tr>
                <td class="sqin">24</td>
                <td>4x6</td>
                <td v-if="chooseOption === '1'">$6.18</td>
                <td v-if="chooseOption === '2'">$4.94</td>
                <td v-if="chooseOption === '3'">$3.71</td>
                <td v-if="chooseOption === '4'">$3.09</td>
                <td v-if="chooseOption === '5'">$2.47</td>
                <td v-if="chooseOption === '6'">$2.16</td>
              </tr>
              <tr>
                <td class="sqin">35</td>
                <td>5x7</td>
                <td v-if="chooseOption === '1'">$7.93</td>
                <td v-if="chooseOption === '2'">$6.34</td>
                <td v-if="chooseOption === '3'">$4.76</td>
                <td v-if="chooseOption === '4'">$3.97</td>
                <td v-if="chooseOption === '5'">$3.17</td>
                <td v-if="chooseOption === '6'">$2.77</td>
              </tr>
              <tr>
                <td class="sqin">49</td>
                <td>7x7</td>
                <td v-if="chooseOption === '1'">$10.60</td>
                <td v-if="chooseOption === '2'">$8.48</td>
                <td v-if="chooseOption === '3'">$6.36</td>
                <td v-if="chooseOption === '4'">$5.29</td>
                <td v-if="chooseOption === '5'">$4.24</td>
                <td v-if="chooseOption === '6'">$3.71</td>
              </tr>
              <tr>
                <td class="sqin">80</td>
                <td>8x10</td>
                <td v-if="chooseOption === '1'">$16.48</td>
                <td v-if="chooseOption === '2'">$13.18</td>
                <td v-if="chooseOption === '3'">$9.89</td>
                <td v-if="chooseOption === '4'">$8.24</td>
                <td v-if="chooseOption === '5'">$6.59</td>
                <td v-if="chooseOption === '6'">$5.77</td>
              </tr>
              <tr>
                <td class="sqin">108</td>
                <td>9x12</td>
                <td v-if="chooseOption === '1'">$19.47</td>
                <td v-if="chooseOption === '2'">$15.66</td>
                <td v-if="chooseOption === '3'">$11.68</td>
                <td v-if="chooseOption === '4'">$9.73</td>
                <td v-if="chooseOption === '5'">$7.79</td>
                <td v-if="chooseOption === '6'">$6.81</td>
              </tr>
              <tr>
                <td class="sqin">120</td>
                <td>10x12</td>
                <td v-if="chooseOption === '1'">$21.01</td>
                <td v-if="chooseOption === '2'">$16.81</td>
                <td v-if="chooseOption === '3'">$12.61</td>
                <td v-if="chooseOption === '4'">$10.51</td>
                <td v-if="chooseOption === '5'">$8.40</td>
                <td v-if="chooseOption === '6'">$7.35</td>
              </tr>
              <tr>
                <td class="sqin">144</td>
                <td>12x12</td>
                <td v-if="chooseOption === '1'">$22.54</td>
                <td v-if="chooseOption === '2'">$18.13</td>
                <td v-if="chooseOption === '3'">$13.51</td>
                <td v-if="chooseOption === '4'">$11.27</td>
                <td v-if="chooseOption === '5'">$9.01</td>
                <td v-if="chooseOption === '6'">$7.88</td>
              </tr>
              <tr>
                <td class="sqin">154</td>
                <td>11x14</td>
                <td v-if="chooseOption === '1'">$23.79</td>
                <td v-if="chooseOption === '2'">$18.95</td>
                <td v-if="chooseOption === '3'">$14.28</td>
                <td v-if="chooseOption === '4'">$11.90</td>
                <td v-if="chooseOption === '5'">$9.52</td>
                <td v-if="chooseOption === '6'">$8.32</td>
              </tr>
              <tr>
                <td class="sqin">187</td>
                <td>11x17</td>
                <td v-if="chooseOption === '1'">$27.73</td>
                <td v-if="chooseOption === '2'">$22.25</td>
                <td v-if="chooseOption === '3'">$16.63</td>
                <td v-if="chooseOption === '4'">$13.86</td>
                <td v-if="chooseOption === '5'">$11.08</td>
                <td v-if="chooseOption === '6'">$9.70</td>
              </tr>
              <tr>
                <td class="sqin">192</td>
                <td>12x16</td>
                <td v-if="chooseOption === '1'">$28.47</td>
                <td v-if="chooseOption === '2'">$23.07</td>
                <td v-if="chooseOption === '3'">$17.08</td>
                <td v-if="chooseOption === '4'">$14.23</td>
                <td v-if="chooseOption === '5'">$11.38</td>
                <td v-if="chooseOption === '6'">$9.96</td>
              </tr>
              <tr>
                <td class="sqin">216</td>
                <td>12x18</td>
                <td v-if="chooseOption === '1'">$31.15</td>
                <td v-if="chooseOption === '2'">$26.37</td>
                <td v-if="chooseOption === '3'">$18.68</td>
                <td v-if="chooseOption === '4'">$15.57</td>
                <td v-if="chooseOption === '5'">$12.45</td>
                <td v-if="chooseOption === '6'">$10.90</td>
              </tr>
              <tr>
                <td class="sqin">252</td>
                <td>14x18</td>
                <td v-if="chooseOption === '1'">$35.04</td>
                <td v-if="chooseOption === '2'">$28.03</td>
                <td v-if="chooseOption === '3'">$21.02</td>
                <td v-if="chooseOption === '4'">$17.52</td>
                <td v-if="chooseOption === '5'">$14.01</td>
                <td v-if="chooseOption === '6'">$12.27</td>
              </tr>
              <tr>
                <td class="sqin">320</td>
                <td>16x20</td>
                <td v-if="chooseOption === '1'">$42.85</td>
                <td v-if="chooseOption === '2'">$34.28</td>
                <td v-if="chooseOption === '3'">$25.71</td>
                <td v-if="chooseOption === '4'">$21.42</td>
                <td v-if="chooseOption === '5'">$17.14</td>
                <td v-if="chooseOption === '6'">$15.00</td>
              </tr>
              <tr>
                <td class="sqin">324</td>
                <td>18x18</td>
                <td v-if="chooseOption === '1'">$43.38</td>
                <td v-if="chooseOption === '2'">$34.70</td>
                <td v-if="chooseOption === '3'">$26.03</td>
                <td v-if="chooseOption === '4'">$21.69</td>
                <td v-if="chooseOption === '5'">$17.35</td>
                <td v-if="chooseOption === '6'">$15.18</td>
              </tr>
              <tr>
                <td class="sqin">384</td>
                <td>16x24</td>
                <td v-if="chooseOption === '1'">$50.62</td>
                <td v-if="chooseOption === '2'">$40.38</td>
                <td v-if="chooseOption === '3'">$30.37</td>
                <td v-if="chooseOption === '4'">$25.31</td>
                <td v-if="chooseOption === '5'">$20.25</td>
                <td v-if="chooseOption === '6'">$17.72</td>
              </tr>
              <tr>
                <td class="sqin">432</td>
                <td>18x24</td>
                <td v-if="chooseOption === '1'">$54.28</td>
                <td v-if="chooseOption === '2'">$43.67</td>
                <td v-if="chooseOption === '3'">$32.57</td>
                <td v-if="chooseOption === '4'">$27.14</td>
                <td v-if="chooseOption === '5'">$21.71</td>
                <td v-if="chooseOption === '6'">$18.99</td>
              </tr>
              <tr>
                <td class="sqin">480</td>
                <td>20x24</td>
                <td v-if="chooseOption === '1'">$60.32</td>
                <td v-if="chooseOption === '2'">$48.62</td>
                <td v-if="chooseOption === '3'">$36.18</td>
                <td v-if="chooseOption === '4'">$30.16</td>
                <td v-if="chooseOption === '5'">$24.12</td>
                <td v-if="chooseOption === '6'">$21.10</td>
              </tr>
              <tr>
                <td class="sqin">576</td>
                <td>24x24</td>
                <td v-if="chooseOption === '1'">$65.26</td>
                <td v-if="chooseOption === '2'">$51.91</td>
                <td v-if="chooseOption === '3'">$39.15</td>
                <td v-if="chooseOption === '4'">$32.63</td>
                <td v-if="chooseOption === '5'">$26.10</td>
                <td v-if="chooseOption === '6'">$22.84</td>
              </tr>
              <tr>
                <td class="sqin">600</td>
                <td>20x30</td>
                <td v-if="chooseOption === '1'">$67.98</td>
                <td v-if="chooseOption === '2'">$54.38</td>
                <td v-if="chooseOption === '3'">$40.79</td>
                <td v-if="chooseOption === '4'">$33.99</td>
                <td v-if="chooseOption === '5'">$27.19</td>
                <td v-if="chooseOption === '6'">$23.79</td>
              </tr>
              <tr>
                <td class="sqin">704</td>
                <td>22x32</td>
                <td v-if="chooseOption === '1'">$76.14</td>
                <td v-if="chooseOption === '2'">$60.91</td>
                <td v-if="chooseOption === '3'">$45.68</td>
                <td v-if="chooseOption === '4'">$38.07</td>
                <td v-if="chooseOption === '5'">$30.47</td>
                <td v-if="chooseOption === '6'">$26.65</td>
              </tr>
              <tr>
                <td class="sqin">720</td>
                <td>24x30</td>
                <td v-if="chooseOption === '1'">$77.87</td>
                <td v-if="chooseOption === '2'">$62.29</td>
                <td v-if="chooseOption === '3'">$46.72</td>
                <td v-if="chooseOption === '4'">$38.93</td>
                <td v-if="chooseOption === '5'">$31.15</td>
                <td v-if="chooseOption === '6'">$27.25</td>
              </tr>
              <tr>
                <td class="sqin">864</td>
                <td>24x36</td>
                <td v-if="chooseOption === '1'">$89.10</td>
                <td v-if="chooseOption === '2'">$70.86</td>
                <td v-if="chooseOption === '3'">$53.46</td>
                <td v-if="chooseOption === '4'">$44.55</td>
                <td v-if="chooseOption === '5'">$35.64</td>
                <td v-if="chooseOption === '6'">$20.88</td>
              </tr>
              <tr>
                <td class="sqin">1200</td>
                <td>30x40</td>
                <td v-if="chooseOption === '1'">$111.24</td>
                <td v-if="chooseOption === '2'">$88.99</td>
                <td v-if="chooseOption === '3'">$66.74</td>
                <td v-if="chooseOption === '4'">$55.62</td>
                <td v-if="chooseOption === '5'">$44.50</td>
                <td v-if="chooseOption === '6'">$38.93</td>
              </tr>
              <tr>
                <td class="sqin">1296</td>
                <td>36x36</td>
                <td v-if="chooseOption === '1'">$120.14</td>
                <td v-if="chooseOption === '2'">$96.41</td>
                <td v-if="chooseOption === '3'">$72.08</td>
                <td v-if="chooseOption === '4'">$60.07</td>
                <td v-if="chooseOption === '5'">$48.05</td>
                <td v-if="chooseOption === '6'">$42.04</td>
              </tr>
              <tr>
                <td class="sqin">1536</td>
                <td>32x48</td>
                <td v-if="chooseOption === '1'">$137.64</td>
                <td v-if="chooseOption === '2'">$110.11</td>
                <td v-if="chooseOption === '3'">$82.59</td>
                <td v-if="chooseOption === '4'">$68.82</td>
                <td v-if="chooseOption === '5'">$55.05</td>
                <td v-if="chooseOption === '6'">$48.17</td>
              </tr>
              <tr>
                <td class="sqin">1728</td>
                <td>36x48</td>
                <td v-if="chooseOption === '1'">$147.72</td>
                <td v-if="chooseOption === '2'">$117.83</td>
                <td v-if="chooseOption === '3'">$88.63</td>
                <td v-if="chooseOption === '4'">$73.86</td>
                <td v-if="chooseOption === '5'">$59.08</td>
                <td v-if="chooseOption === '6'">$51.70</td>
              </tr>
              <tr>
                <td class="sqin">1944</td>
                <td>36x54</td>
                <td v-if="chooseOption === '1'">$166.19</td>
                <td v-if="chooseOption === '2'">$132.66</td>
                <td v-if="chooseOption === '3'">$99.71</td>
                <td v-if="chooseOption === '4'">$83.09</td>
                <td v-if="chooseOption === '5'">$66.58</td>
                <td v-if="chooseOption === '6'">$58.16</td>
              </tr>
              <tr>
                <td class="sqin">2400</td>
                <td>40x60</td>
                <td v-if="chooseOption === '1'">$197.76</td>
                <td v-if="chooseOption === '2'">$154.09</td>
                <td v-if="chooseOption === '3'">$118.66</td>
                <td v-if="chooseOption === '4'">$98.88</td>
                <td v-if="chooseOption === '5'">$79.10</td>
                <td v-if="chooseOption === '6'">$69.22</td>
              </tr>
              <tr>
                <td class="sqin">2880</td>
                <td>40x72</td>
                <td v-if="chooseOption === '1'">$237.31</td>
                <td v-if="chooseOption === '2'">$189.52</td>
                <td v-if="chooseOption === '3'">$142.39</td>
                <td v-if="chooseOption === '4'">$118.66</td>
                <td v-if="chooseOption === '5'">$94.92</td>
                <td v-if="chooseOption === '6'">$83.06</td>
              </tr>
              <tr>
                <td class="sqin">3456</td>
                <td>48x72</td>
                <td v-if="chooseOption === '1'">$284.77</td>
                <td v-if="chooseOption === '2'">$227.42</td>
                <td v-if="chooseOption === '3'">$170.86</td>
                <td v-if="chooseOption === '4'">$142.39</td>
                <td v-if="chooseOption === '5'">$113.91</td>
                <td v-if="chooseOption === '6'">$99.66</td>
              </tr>
              <tr>
                <td class="sqin">4374</td>
                <td>54x81</td>
                <td v-if="chooseOption === '1'">$360.42</td>
                <td v-if="chooseOption === '2'">$288.40</td>
                <td v-if="chooseOption === '3'">$216.25</td>
                <td v-if="chooseOption === '4'">$180.21</td>
                <td v-if="chooseOption === '5'">$144.16</td>
                <td v-if="chooseOption === '6'">$126.14</td>
              </tr>
              <tr>
                <td class="sqin">5400</td>
                <td>54x100</td>
                <td v-if="chooseOption === '1'">$389.34</td>
                <td v-if="chooseOption === '2'">$311.47</td>
                <td v-if="chooseOption === '3'">$233.60</td>
                <td v-if="chooseOption === '4'">$194.67</td>
                <td v-if="chooseOption === '5'">$155.74</td>
                <td v-if="chooseOption === '6'">$136.27</td>
              </tr>
            </table>
          </div>
        </details>
          <br>
    </details>
    <br/>
    <details>
      <summary style="font-weight: 700; text-transform: uppercase; letter-spacing: 1px; opacity: 0.8; font-size: 1.0em;">
        Economy Fine Art
      </summary>
      <br/>
      <p>
        Our economy paper is Lexjet Premium Archival Matte. This is a thinner fine art paper, with a very bright white point, you don’t often see in fine art papers. In contrast to our premium fine art papers, this paper only has a smooth finish. It is acid free, with a weight of 230gsm.
      </p>
      <br/>
      <p>
        This paper should optimally be used for presenting artwork in a different aesthetic than traditional fine art papers, as mentioned above it is much brighter than traditional papers, which can be beneficial for bold graphical work, digital art or wanting to differentiate your fine art prints from your original.
      </p>
      <br/>
      <p>
        The paper requires UV-protection for longevity from fading, as well as care in handling, especially for darker planes of color, as the inks to reproduce these colors are highly pigmented, and therefore prone to scuffing.
      </p>
      <br/>
        <details>
        <summary style="font-weight: 700; opacity: 0.85; text-decoration: underline; text-decoration-color: rgba(242, 157, 75, 0.50); text-underline-offset: 2px;">
          Economy Fine Art Pricelist
        </summary>
        <br/>
        <p>
        <strong>
        Quantity discounts for printing only apply to the same image, on one paper, being printed at the same size in the same order.
        </strong>
        </p>
        <p>
        For custom sizes, round up to nearest square inch.
        </p>
        <br/>
          <div>
            <table>
              <tr>
                <th>Sq. In.</th>
                <th>Std. Size</th>
                <th>
                  <select class="form-select" v-model="chooseOption">
                    <option value="" disabled selected>Quantity</option>        
                    <option value="1">1</option>
                    <option value="2">2-9</option>
                    <option value="3">10-19</option>
                    <option value="4">20-49</option>
                    <option value="5">50-99</option>
                    <option value="6">100+</option>
                  </select>
                </th>
              </tr>
              <tr>
                <td class="sqin">12</td>
                <td>3x4</td>
                <td v-if="chooseOption === '1'">$3.68</td>
                <td v-if="chooseOption === '2'">$2.95</td>
                <td v-if="chooseOption === '3'">$2.20</td>
                <td v-if="chooseOption === '4'">$1.84</td>
                <td v-if="chooseOption === '5'">$1.47</td>
                <td v-if="chooseOption === '6'">$1.29</td>
              </tr>
              <tr>
                <td class="sqin">24</td>
                <td>4x6</td>
                <td v-if="chooseOption === '1'">$5.25</td>
                <td v-if="chooseOption === '2'">$4.20</td>
                <td v-if="chooseOption === '3'">$3.15</td>
                <td v-if="chooseOption === '4'">$2.63</td>
                <td v-if="chooseOption === '5'">$2.10</td>
                <td v-if="chooseOption === '6'">$1.84</td>
              </tr>
              <tr>
                <td class="sqin">35</td>
                <td>5x7</td>
                <td v-if="chooseOption === '1'">$6.75</td>
                <td v-if="chooseOption === '2'">$5.40</td>
                <td v-if="chooseOption === '3'">$4.05</td>
                <td v-if="chooseOption === '4'">$3.37</td>
                <td v-if="chooseOption === '5'">$2.70</td>
                <td v-if="chooseOption === '6'">$2.36</td>
              </tr>
              <tr>
                <td class="sqin">49</td>
                <td>7x7</td>
                <td v-if="chooseOption === '1'">$9.08</td>
                <td v-if="chooseOption === '2'">$7.26</td>
                <td v-if="chooseOption === '3'">$5.45</td>
                <td v-if="chooseOption === '4'">$4.54</td>
                <td v-if="chooseOption === '5'">$3.63</td>
                <td v-if="chooseOption === '6'">$3.18</td>
              </tr>
              <tr>
                <td class="sqin">64</td>
                <td>8x8</td>
                <td v-if="chooseOption === '1'">$11.87</td>
                <td v-if="chooseOption === '2'">$9.49</td>
                <td v-if="chooseOption === '3'">$7.12</td>
                <td v-if="chooseOption === '4'">$5.93</td>
                <td v-if="chooseOption === '5'">$4.75</td>
                <td v-if="chooseOption === '6'">$4.15</td>
              </tr>
              <tr>
                <td class="sqin">80</td>
                <td>8x10</td>
                <td v-if="chooseOption === '1'">$14.01</td>
                <td v-if="chooseOption === '2'">$11.21</td>
                <td v-if="chooseOption === '3'">$8.40</td>
                <td v-if="chooseOption === '4'">$7.00</td>
                <td v-if="chooseOption === '5'">$5.60</td>
                <td v-if="chooseOption === '6'">$4.90</td>
              </tr>
              <tr>
                <td class="sqin">108</td>
                <td>9x12</td>
                <td v-if="chooseOption === '1'">$16.54</td>
                <td v-if="chooseOption === '2'">$13.23</td>
                <td v-if="chooseOption === '3'">$9.92</td>
                <td v-if="chooseOption === '4'">$8.27</td>
                <td v-if="chooseOption === '5'">$6.61</td>
                <td v-if="chooseOption === '6'">$5.79</td>
              </tr>
              <tr>
                <td class="sqin">120</td>
                <td>10x12</td>
                <td v-if="chooseOption === '1'">$18.38</td>
                <td v-if="chooseOption === '2'">$14.71</td>
                <td v-if="chooseOption === '3'">$11.03</td>
                <td v-if="chooseOption === '4'">$9.19</td>
                <td v-if="chooseOption === '5'">$7.35</td>
                <td v-if="chooseOption === '6'">$6.44</td>
              </tr>
              <tr>
                <td class="sqin">144</td>
                <td>12x12</td>
                <td v-if="chooseOption === '1'">$18.03</td>
                <td v-if="chooseOption === '2'">$14.42</td>
                <td v-if="chooseOption === '3'">$10.82</td>
                <td v-if="chooseOption === '4'">$9.01</td>
                <td v-if="chooseOption === '5'">$7.21</td>
                <td v-if="chooseOption === '6'">$6.31</td>
              </tr>
              <tr>
                <td class="sqin">154</td>
                <td>11x14</td>
                <td v-if="chooseOption === '1'">$20.22</td>
                <td v-if="chooseOption === '2'">$16.17</td>
                <td v-if="chooseOption === '3'">$12.12</td>
                <td v-if="chooseOption === '4'">$10.10</td>
                <td v-if="chooseOption === '5'">$8.09</td>
                <td v-if="chooseOption === '6'">$7.08</td>
              </tr>
              <tr>
                <td class="sqin">187</td>
                <td>11x17</td>
                <td v-if="chooseOption === '1'">$23.50</td>
                <td v-if="chooseOption === '2'">$18.80</td>
                <td v-if="chooseOption === '3'">$14.10</td>
                <td v-if="chooseOption === '4'">$11.71</td>
                <td v-if="chooseOption === '5'">$9.39</td>
                <td v-if="chooseOption === '6'">$8.23</td>
              </tr>
              <tr>
                <td class="sqin">192</td>
                <td>12x16</td>
                <td v-if="chooseOption === '1'">$24.19</td>
                <td v-if="chooseOption === '2'">$19.35</td>
                <td v-if="chooseOption === '3'">$14.51</td>
                <td v-if="chooseOption === '4'">$12.09</td>
                <td v-if="chooseOption === '5'">$9.67</td>
                <td v-if="chooseOption === '6'">$8.47</td>
              </tr>
              <tr>
                <td class="sqin">216</td>
                <td>12x18</td>
                <td v-if="chooseOption === '1'">$26.47</td>
                <td v-if="chooseOption === '2'">$21.18</td>
                <td v-if="chooseOption === '3'">$15.88</td>
                <td v-if="chooseOption === '4'">$13.24</td>
                <td v-if="chooseOption === '5'">$10.59</td>
                <td v-if="chooseOption === '6'">$9.27</td>
              </tr>
              <tr>
                <td class="sqin">252</td>
                <td>14x18</td>
                <td v-if="chooseOption === '1'">$28.84</td>
                <td v-if="chooseOption === '2'">$23.07</td>
                <td v-if="chooseOption === '3'">$17.30</td>
                <td v-if="chooseOption === '4'">$14.42</td>
                <td v-if="chooseOption === '5'">$11.54</td>
                <td v-if="chooseOption === '6'">$10.09</td>
              </tr>
              <tr>
                <td class="sqin">320</td>
                <td>16x20</td>
                <td v-if="chooseOption === '1'">$36.42</td>
                <td v-if="chooseOption === '2'">$29.13</td>
                <td v-if="chooseOption === '3'">$21.85</td>
                <td v-if="chooseOption === '4'">$18.21</td>
                <td v-if="chooseOption === '5'">$14.56</td>
                <td v-if="chooseOption === '6'">$12.75</td>
              </tr>
              <tr>
                <td class="sqin">324</td>
                <td>18x18</td>
                <td v-if="chooseOption === '1'">$36.67</td>
                <td v-if="chooseOption === '2'">$29.50</td>
                <td v-if="chooseOption === '3'">$22.12</td>
                <td v-if="chooseOption === '4'">$18.44</td>
                <td v-if="chooseOption === '5'">$14.75</td>
                <td v-if="chooseOption === '6'">$12.91</td>
              </tr>
              <tr>
                <td class="sqin">384</td>
                <td>16x24</td>
                <td v-if="chooseOption === '1'">$43.02</td>
                <td v-if="chooseOption === '2'">$34.41</td>
                <td v-if="chooseOption === '3'">$25.81</td>
                <td v-if="chooseOption === '4'">$21.51</td>
                <td v-if="chooseOption === '5'">$17.20</td>
                <td v-if="chooseOption === '6'">$15.06</td>
              </tr>
              <tr>
                <td class="sqin">432</td>
                <td>18x24</td>
                <td v-if="chooseOption === '1'">$46.13</td>
                <td v-if="chooseOption === '2'">$36.90</td>
                <td v-if="chooseOption === '3'">$27.68</td>
                <td v-if="chooseOption === '4'">$23.06</td>
                <td v-if="chooseOption === '5'">$18.45</td>
                <td v-if="chooseOption === '6'">$16.15</td>
              </tr>
              <tr>
                <td class="sqin">480</td>
                <td>20x24</td>
                <td v-if="chooseOption === '1'">$51.26</td>
                <td v-if="chooseOption === '2'">$41.00</td>
                <td v-if="chooseOption === '3'">$30.76</td>
                <td v-if="chooseOption === '4'">$25.63</td>
                <td v-if="chooseOption === '5'">$20.50</td>
                <td v-if="chooseOption === '6'">$17.94</td>
              </tr>
              <tr>
                <td class="sqin">576</td>
                <td>24x24</td>
                <td v-if="chooseOption === '1'">$55.47</td>
                <td v-if="chooseOption === '2'">$44.37</td>
                <td v-if="chooseOption === '3'">$33.28</td>
                <td v-if="chooseOption === '4'">$27.73</td>
                <td v-if="chooseOption === '5'">$22.19</td>
                <td v-if="chooseOption === '6'">$19.42</td>
              </tr>
              <tr>
                <td class="sqin">600</td>
                <td>20x30</td>
                <td v-if="chooseOption === '1'">$57.78</td>
                <td v-if="chooseOption === '2'">$46.23</td>
                <td v-if="chooseOption === '3'">$34.67</td>
                <td v-if="chooseOption === '4'">$28.89</td>
                <td v-if="chooseOption === '5'">$23.11</td>
                <td v-if="chooseOption === '6'">$20.23</td>
              </tr>
              <tr>
                <td class="sqin">704</td>
                <td>22x32</td>
                <td v-if="chooseOption === '1'">$65.92</td>
                <td v-if="chooseOption === '2'">$52.74</td>
                <td v-if="chooseOption === '3'">$39.55</td>
                <td v-if="chooseOption === '4'">$32.96</td>
                <td v-if="chooseOption === '5'">$26.37</td>
                <td v-if="chooseOption === '6'">$23.07</td>
              </tr>
              <tr>
                <td class="sqin">720</td>
                <td>24x30</td>
                <td v-if="chooseOption === '1'">$63.11</td>
                <td v-if="chooseOption === '2'">$50.48</td>
                <td v-if="chooseOption === '3'">$37.86</td>
                <td v-if="chooseOption === '4'">$31.55</td>
                <td v-if="chooseOption === '5'">$25.25</td>
                <td v-if="chooseOption === '6'">$22.08</td>
              </tr>
              <tr>
                <td class="sqin">864</td>
                <td>24x36</td>
                <td v-if="chooseOption === '1'">$75.73</td>
                <td v-if="chooseOption === '2'">$60.57</td>
                <td v-if="chooseOption === '3'">$45.43</td>
                <td v-if="chooseOption === '4'">$37.86</td>
                <td v-if="chooseOption === '5'">$30.28</td>
                <td v-if="chooseOption === '6'">$26.50</td>
              </tr>
              <tr>
                <td class="sqin">1200</td>
                <td>30x40</td>
                <td v-if="chooseOption === '1'">$94.55</td>
                <td v-if="chooseOption === '2'">$75.64</td>
                <td v-if="chooseOption === '3'">$56.73</td>
                <td v-if="chooseOption === '4'">$47.28</td>
                <td v-if="chooseOption === '5'">$37.82</td>
                <td v-if="chooseOption === '6'">$33.08</td>
              </tr>
              <tr>
                <td class="sqin">1296</td>
                <td>36x36</td>
                <td v-if="chooseOption === '1'">$102.11</td>
                <td v-if="chooseOption === '2'">$81.69</td>
                <td v-if="chooseOption === '3'">$61.26</td>
                <td v-if="chooseOption === '4'">$51.06</td>
                <td v-if="chooseOption === '5'">$40.84</td>
                <td v-if="chooseOption === '6'">$35.74</td>
              </tr>
              <tr>
                <td class="sqin">1536</td>
                <td>32x48</td>
                <td v-if="chooseOption === '1'">$111.24</td>
                <td v-if="chooseOption === '2'">$88.99</td>
                <td v-if="chooseOption === '3'">$66.74</td>
                <td v-if="chooseOption === '4'">$55.62</td>
                <td v-if="chooseOption === '5'">$44.50</td>
                <td v-if="chooseOption === '6'">$38.93</td>
              </tr>
              <tr>
                <td class="sqin">1728</td>
                <td>36x48</td>
                <td v-if="chooseOption === '1'">$125.56</td>
                <td v-if="chooseOption === '2'">$100.45</td>
                <td v-if="chooseOption === '3'">$75.33</td>
                <td v-if="chooseOption === '4'">$62.78</td>
                <td v-if="chooseOption === '5'">$50.22</td>
                <td v-if="chooseOption === '6'">$43.95</td>
              </tr>
              <tr>
                <td class="sqin">1944</td>
                <td>36x54</td>
                <td v-if="chooseOption === '1'">$141.25</td>
                <td v-if="chooseOption === '2'">$113.00</td>
                <td v-if="chooseOption === '3'">$84.75</td>
                <td v-if="chooseOption === '4'">$70.63</td>
                <td v-if="chooseOption === '5'">$56.5</td>
                <td v-if="chooseOption === '6'">$49.44</td>
              </tr>
              <tr>
                <td class="sqin">2400</td>
                <td>40x60</td>
                <td v-if="chooseOption === '1'">$168.10</td>
                <td v-if="chooseOption === '2'">$134.48</td>
                <td v-if="chooseOption === '3'">$100.86</td>
                <td v-if="chooseOption === '4'">$84.05</td>
                <td v-if="chooseOption === '5'">$67.24</td>
                <td v-if="chooseOption === '6'">$58.83</td>
              </tr>
              <tr>
                <td class="sqin">2880</td>
                <td>40x72</td>
                <td v-if="chooseOption === '1'">$201.72</td>
                <td v-if="chooseOption === '2'">$161.37</td>
                <td v-if="chooseOption === '3'">$121.03</td>
                <td v-if="chooseOption === '4'">$100.86</td>
                <td v-if="chooseOption === '5'">$80.68</td>
                <td v-if="chooseOption === '6'">$70.60</td>
              </tr>
              <tr>
                <td class="sqin">3456</td>
                <td>48x72</td>
                <td v-if="chooseOption === '1'">$242.05</td>
                <td v-if="chooseOption === '2'">$193.64</td>
                <td v-if="chooseOption === '3'">$145.23</td>
                <td v-if="chooseOption === '4'">$121.03</td>
                <td v-if="chooseOption === '5'">$96.82</td>
                <td v-if="chooseOption === '6'">$84.72</td>
              </tr>
              <tr>
                <td class="sqin">4374</td>
                <td>54x81</td>
                <td v-if="chooseOption === '1'">$306.35</td>
                <td v-if="chooseOption === '2'">$245.08</td>
                <td v-if="chooseOption === '3'">$183.80</td>
                <td v-if="chooseOption === '4'">$153.17</td>
                <td v-if="chooseOption === '5'">$122.54</td>
                <td v-if="chooseOption === '6'">$107.22</td>
              </tr>
              <tr>
                <td class="sqin">5400</td>
                <td>54x100</td>
                <td v-if="chooseOption === '1'">$330.94</td>
                <td v-if="chooseOption === '2'">$264.75</td>
                <td v-if="chooseOption === '3'">$198.56</td>
                <td v-if="chooseOption === '4'">$165.47</td>
                <td v-if="chooseOption === '5'">$132.38</td>
                <td v-if="chooseOption === '6'">$115.83</td>
              </tr>
            </table>
          </div>
        </details>
          <br>
    </details>
    <br/>
    <details>
      <summary style="font-weight: 700; text-transform: uppercase; letter-spacing: 1px; opacity: 0.8; font-size: 1.0em;">
        Photographic
      </summary>
      <br/>
      <p>
        Our photographic paper is Epson Premium Semi-matte 260gsm, which is a wide gamut photographic paper, that in contrast to any matte fine art paper, can reproduce a larger spectrum of color, greater tonal range and sharper details. This has to do with the physical aspects of the paper, as the surface has a very uniform smooth coating, allowing for greater detail and ink retention. As implied by the name, the paper has a semi-matte surface, which allows for more light to be reflected in contrast to a matte cotton rag, which in turn means “brighter” colors. This is why this kind of medium is a standard for photographic printing, and the photographer that wants to present their work in a more traditional photographic fashion. However, for the right piece of artwork, it can also be a great choice, as the colors will be brighter and the contrast greater.
      </p>
      <br/>
      <p>
        The paper requires UV-protection for longevity from fading, as well as care in handling, especially for darker planes of color, as the inks to reproduce these colors are highly pigmented, and therefore prone to scratching.
      </p>
      <br/>
        <details>
        <summary style="font-weight: 700; opacity: 0.85; text-decoration: underline; text-decoration-color: rgba(242, 157, 75, 0.50); text-underline-offset: 2px;">
          Photographic Pricelist
        </summary>
        <br/>
        <p>
        <strong>
        Quantity discounts for printing only apply to the same image, on one paper, being printed at the same size in the same order.
        </strong>
        </p>
        <p>
        For custom sizes, round up to nearest square inch.
        </p>
        <br/>
          <div>
            <table>
              <tr>
                <th>Sq. In.</th>
                <th>Std. Size</th>
                <th>
                  <select class="form-select" v-model="chooseOption">
                    <option value="" disabled selected>Quantity</option>        
                    <option value="1">1</option>
                    <option value="2">2-9</option>
                    <option value="3">10-19</option>
                    <option value="4">20-49</option>
                    <option value="5">50-99</option>
                    <option value="6">100+</option>
                  </select>
                </th>
              </tr>
              <tr>
                <td class="sqin">12</td>
                <td>3x4</td>
                <td v-if="chooseOption === '1'">$3.68</td>
                <td v-if="chooseOption === '2'">$2.95</td>
                <td v-if="chooseOption === '3'">$2.20</td>
                <td v-if="chooseOption === '4'">$1.84</td>
                <td v-if="chooseOption === '5'">$1.47</td>
                <td v-if="chooseOption === '6'">$1.29</td>
              </tr>
              <tr>
                <td class="sqin">24</td>
                <td>4x6</td>
                <td v-if="chooseOption === '1'">$5.25</td>
                <td v-if="chooseOption === '2'">$4.20</td>
                <td v-if="chooseOption === '3'">$3.15</td>
                <td v-if="chooseOption === '4'">$2.63</td>
                <td v-if="chooseOption === '5'">$2.10</td>
                <td v-if="chooseOption === '6'">$1.84</td>
              </tr>
              <tr>
                <td class="sqin">35</td>
                <td>5x7</td>
                <td v-if="chooseOption === '1'">$6.75</td>
                <td v-if="chooseOption === '2'">$5.40</td>
                <td v-if="chooseOption === '3'">$4.05</td>
                <td v-if="chooseOption === '4'">$3.37</td>
                <td v-if="chooseOption === '5'">$2.70</td>
                <td v-if="chooseOption === '6'">$2.36</td>
              </tr>
              <tr>
                <td class="sqin">49</td>
                <td>7x7</td>
                <td v-if="chooseOption === '1'">$9.08</td>
                <td v-if="chooseOption === '2'">$7.26</td>
                <td v-if="chooseOption === '3'">$5.45</td>
                <td v-if="chooseOption === '4'">$4.54</td>
                <td v-if="chooseOption === '5'">$3.63</td>
                <td v-if="chooseOption === '6'">$3.18</td>
              </tr>
              <tr>
                <td class="sqin">64</td>
                <td>8x8</td>
                <td v-if="chooseOption === '1'">$11.87</td>
                <td v-if="chooseOption === '2'">$9.49</td>
                <td v-if="chooseOption === '3'">$7.12</td>
                <td v-if="chooseOption === '4'">$5.93</td>
                <td v-if="chooseOption === '5'">$4.75</td>
                <td v-if="chooseOption === '6'">$4.15</td>
              </tr>
              <tr>
                <td class="sqin">80</td>
                <td>8x10</td>
                <td v-if="chooseOption === '1'">$14.01</td>
                <td v-if="chooseOption === '2'">$11.21</td>
                <td v-if="chooseOption === '3'">$8.40</td>
                <td v-if="chooseOption === '4'">$7.00</td>
                <td v-if="chooseOption === '5'">$5.60</td>
                <td v-if="chooseOption === '6'">$4.90</td>
              </tr>
              <tr>
                <td class="sqin">108</td>
                <td>9x12</td>
                <td v-if="chooseOption === '1'">$16.54</td>
                <td v-if="chooseOption === '2'">$13.23</td>
                <td v-if="chooseOption === '3'">$9.92</td>
                <td v-if="chooseOption === '4'">$8.27</td>
                <td v-if="chooseOption === '5'">$6.61</td>
                <td v-if="chooseOption === '6'">$5.79</td>
              </tr>
              <tr>
                <td class="sqin">120</td>
                <td>10x12</td>
                <td v-if="chooseOption === '1'">$18.38</td>
                <td v-if="chooseOption === '2'">$14.71</td>
                <td v-if="chooseOption === '3'">$11.03</td>
                <td v-if="chooseOption === '4'">$9.19</td>
                <td v-if="chooseOption === '5'">$7.35</td>
                <td v-if="chooseOption === '6'">$6.44</td>
              </tr>
              <tr>
                <td class="sqin">144</td>
                <td>12x12</td>
                <td v-if="chooseOption === '1'">$18.03</td>
                <td v-if="chooseOption === '2'">$14.42</td>
                <td v-if="chooseOption === '3'">$10.82</td>
                <td v-if="chooseOption === '4'">$9.091</td>
                <td v-if="chooseOption === '5'">$7.21</td>
                <td v-if="chooseOption === '6'">$6.31</td>
              </tr>
              <tr>
                <td class="sqin">154</td>
                <td>11x14</td>
                <td v-if="chooseOption === '1'">$20.22</td>
                <td v-if="chooseOption === '2'">$16.17</td>
                <td v-if="chooseOption === '3'">$12.12</td>
                <td v-if="chooseOption === '4'">$10.10</td>
                <td v-if="chooseOption === '5'">$8.09</td>
                <td v-if="chooseOption === '6'">$7.08</td>
              </tr>
              <tr>
                <td class="sqin">187</td>
                <td>11x17</td>
                <td v-if="chooseOption === '1'">$23.50</td>
                <td v-if="chooseOption === '2'">$18.80</td>
                <td v-if="chooseOption === '3'">$14.10</td>
                <td v-if="chooseOption === '4'">$11.71</td>
                <td v-if="chooseOption === '5'">$9.39</td>
                <td v-if="chooseOption === '6'">$8.23</td>
              </tr>
              <tr>
                <td class="sqin">192</td>
                <td>12x16</td>
                <td v-if="chooseOption === '1'">$24.19</td>
                <td v-if="chooseOption === '2'">$19.35</td>
                <td v-if="chooseOption === '3'">$14.51</td>
                <td v-if="chooseOption === '4'">$12.09</td>
                <td v-if="chooseOption === '5'">$9.67</td>
                <td v-if="chooseOption === '6'">$8.47</td>
              </tr>
              <tr>
                <td class="sqin">216</td>
                <td>12x18</td>
                <td v-if="chooseOption === '1'">$26.47</td>
                <td v-if="chooseOption === '2'">$21.18</td>
                <td v-if="chooseOption === '3'">$15.88</td>
                <td v-if="chooseOption === '4'">$13.24</td>
                <td v-if="chooseOption === '5'">$10.59</td>
                <td v-if="chooseOption === '6'">$9.27</td>
              </tr>
              <tr>
                <td class="sqin">252</td>
                <td>14x18</td>
                <td v-if="chooseOption === '1'">$28.84</td>
                <td v-if="chooseOption === '2'">$23.07</td>
                <td v-if="chooseOption === '3'">$17.30</td>
                <td v-if="chooseOption === '4'">$14.42</td>
                <td v-if="chooseOption === '5'">$11.54</td>
                <td v-if="chooseOption === '6'">$10.09</td>
              </tr>
              <tr>
                <td class="sqin">320</td>
                <td>16x20</td>
                <td v-if="chooseOption === '1'">$36.42</td>
                <td v-if="chooseOption === '2'">$29.13</td>
                <td v-if="chooseOption === '3'">$21.85</td>
                <td v-if="chooseOption === '4'">$18.21</td>
                <td v-if="chooseOption === '5'">$14.56</td>
                <td v-if="chooseOption === '6'">$12.75</td>
              </tr>
              <tr>
                <td class="sqin">324</td>
                <td>18x18</td>
                <td v-if="chooseOption === '1'">$36.87</td>
                <td v-if="chooseOption === '2'">$29.50</td>
                <td v-if="chooseOption === '3'">$22.12</td>
                <td v-if="chooseOption === '4'">$18.44</td>
                <td v-if="chooseOption === '5'">$14.75</td>
                <td v-if="chooseOption === '6'">$12.91</td>
              </tr>
              <tr>
                <td class="sqin">384</td>
                <td>16x24</td>
                <td v-if="chooseOption === '1'">$43.02</td>
                <td v-if="chooseOption === '2'">$34.41</td>
                <td v-if="chooseOption === '3'">$25.81</td>
                <td v-if="chooseOption === '4'">$21.51</td>
                <td v-if="chooseOption === '5'">$17.20</td>
                <td v-if="chooseOption === '6'">$15.06</td>
              </tr>
              <tr>
                <td class="sqin">432</td>
                <td>18x24</td>
                <td v-if="chooseOption === '1'">$46.13</td>
                <td v-if="chooseOption === '2'">$36.90</td>
                <td v-if="chooseOption === '3'">$27.68</td>
                <td v-if="chooseOption === '4'">$23.06</td>
                <td v-if="chooseOption === '5'">$18.45</td>
                <td v-if="chooseOption === '6'">$16.15</td>
              </tr>
              <tr>
                <td class="sqin">480</td>
                <td>20x24</td>
                <td v-if="chooseOption === '1'">$51.26</td>
                <td v-if="chooseOption === '2'">$41.00</td>
                <td v-if="chooseOption === '3'">$30.76</td>
                <td v-if="chooseOption === '4'">$25.63</td>
                <td v-if="chooseOption === '5'">$20.50</td>
                <td v-if="chooseOption === '6'">$17.94</td>
              </tr>
              <tr>
                <td class="sqin">576</td>
                <td>24x24</td>
                <td v-if="chooseOption === '1'">$55.47</td>
                <td v-if="chooseOption === '2'">$44.37</td>
                <td v-if="chooseOption === '3'">$33.28</td>
                <td v-if="chooseOption === '4'">$27.73</td>
                <td v-if="chooseOption === '5'">$22.19</td>
                <td v-if="chooseOption === '6'">$19.42</td>
              </tr>
              <tr>
                <td class="sqin">600</td>
                <td>20x30</td>
                <td v-if="chooseOption === '1'">$57.78</td>
                <td v-if="chooseOption === '2'">$46.23</td>
                <td v-if="chooseOption === '3'">$34.67</td>
                <td v-if="chooseOption === '4'">$28.89</td>
                <td v-if="chooseOption === '5'">$23.11</td>
                <td v-if="chooseOption === '6'">$20.23</td>
              </tr>
              <tr>
                <td class="sqin">704</td>
                <td>22x32</td>
                <td v-if="chooseOption === '1'">$65.92</td>
                <td v-if="chooseOption === '2'">$52.74</td>
                <td v-if="chooseOption === '3'">$39.55</td>
                <td v-if="chooseOption === '4'">$32.96</td>
                <td v-if="chooseOption === '5'">$26.37</td>
                <td v-if="chooseOption === '6'">$23.07</td>
              </tr>
              <tr>
                <td class="sqin">720</td>
                <td>24x30</td>
                <td v-if="chooseOption === '1'">$63.11</td>
                <td v-if="chooseOption === '2'">$50.48</td>
                <td v-if="chooseOption === '3'">$37.86</td>
                <td v-if="chooseOption === '4'">$31.55</td>
                <td v-if="chooseOption === '5'">$25.25</td>
                <td v-if="chooseOption === '6'">$22.08</td>
              </tr>
              <tr>
                <td class="sqin">864</td>
                <td>24x36</td>
                <td v-if="chooseOption === '1'">$75.73</td>
                <td v-if="chooseOption === '2'">$60.57</td>
                <td v-if="chooseOption === '3'">$45.43</td>
                <td v-if="chooseOption === '4'">$37.86</td>
                <td v-if="chooseOption === '5'">$30.28</td>
                <td v-if="chooseOption === '6'">$26.50</td>
              </tr>
              <tr>
                <td class="sqin">1200</td>
                <td>30x40</td>
                <td v-if="chooseOption === '1'">$94.55</td>
                <td v-if="chooseOption === '2'">$75.64</td>
                <td v-if="chooseOption === '3'">$56.73</td>
                <td v-if="chooseOption === '4'">$47.28</td>
                <td v-if="chooseOption === '5'">$37.82</td>
                <td v-if="chooseOption === '6'">$33.08</td>
              </tr>
              <tr>
                <td class="sqin">1296</td>
                <td>36x36</td>
                <td v-if="chooseOption === '1'">$102.11</td>
                <td v-if="chooseOption === '2'">$81.69</td>
                <td v-if="chooseOption === '3'">$61.26</td>
                <td v-if="chooseOption === '4'">$51.06</td>
                <td v-if="chooseOption === '5'">$40.84</td>
                <td v-if="chooseOption === '6'">$35.74</td>
              </tr>
              <tr>
                <td class="sqin">1536</td>
                <td>32x48</td>
                <td v-if="chooseOption === '1'">$111.24</td>
                <td v-if="chooseOption === '2'">$88.99</td>
                <td v-if="chooseOption === '3'">$66.74</td>
                <td v-if="chooseOption === '4'">$55.62</td>
                <td v-if="chooseOption === '5'">$44.50</td>
                <td v-if="chooseOption === '6'">$38.93</td>
              </tr>
              <tr>
                <td class="sqin">1728</td>
                <td>36x48</td>
                <td v-if="chooseOption === '1'">$125.56</td>
                <td v-if="chooseOption === '2'">$100.45</td>
                <td v-if="chooseOption === '3'">$75.33</td>
                <td v-if="chooseOption === '4'">$62.78</td>
                <td v-if="chooseOption === '5'">$50.22</td>
                <td v-if="chooseOption === '6'">$43.95</td>
              </tr>
              <tr>
                <td class="sqin">1944</td>
                <td>36x54</td>
                <td v-if="chooseOption === '1'">$141.25</td>
                <td v-if="chooseOption === '2'">$113.00</td>
                <td v-if="chooseOption === '3'">$84.75</td>
                <td v-if="chooseOption === '4'">$70.63</td>
                <td v-if="chooseOption === '5'">$56.50</td>
                <td v-if="chooseOption === '6'">$49.44</td>
              </tr>
              <tr>
                <td class="sqin">2400</td>
                <td>40x60</td>
                <td v-if="chooseOption === '1'">$168.10</td>
                <td v-if="chooseOption === '2'">$134.48</td>
                <td v-if="chooseOption === '3'">$100.86</td>
                <td v-if="chooseOption === '4'">$84.05</td>
                <td v-if="chooseOption === '5'">$67.24</td>
                <td v-if="chooseOption === '6'">$58.83</td>
              </tr>
              <tr>
                <td class="sqin">2880</td>
                <td>40x72</td>
                <td v-if="chooseOption === '1'">$201.72</td>
                <td v-if="chooseOption === '2'">$161.37</td>
                <td v-if="chooseOption === '3'">$121.03</td>
                <td v-if="chooseOption === '4'">$100.86</td>
                <td v-if="chooseOption === '5'">$80.68</td>
                <td v-if="chooseOption === '6'">$70.60</td>
              </tr>
              <tr>
                <td class="sqin">3456</td>
                <td>48x72</td>
                <td v-if="chooseOption === '1'">$242.05</td>
                <td v-if="chooseOption === '2'">$193.64</td>
                <td v-if="chooseOption === '3'">$145.23</td>
                <td v-if="chooseOption === '4'">$121.03</td>
                <td v-if="chooseOption === '5'">$96.82</td>
                <td v-if="chooseOption === '6'">$84.72</td>
              </tr>
              <tr>
                <td class="sqin">4374</td>
                <td>54x81</td>
                <td v-if="chooseOption === '1'">$306.35</td>
                <td v-if="chooseOption === '2'">$245.08</td>
                <td v-if="chooseOption === '3'">$183.80</td>
                <td v-if="chooseOption === '4'">$153.17</td>
                <td v-if="chooseOption === '5'">$122.54</td>
                <td v-if="chooseOption === '6'">$107.22</td>
              </tr>
              <tr>
                <td class="sqin">5400</td>
                <td>54x100</td>
                <td v-if="chooseOption === '1'">$330.94</td>
                <td v-if="chooseOption === '2'">$264.75</td>
                <td v-if="chooseOption === '3'">$198.56</td>
                <td v-if="chooseOption === '4'">$165.47</td>
                <td v-if="chooseOption === '5'">$132.38</td>
                <td v-if="chooseOption === '6'">$115.83</td>
              </tr>
            </table>
          </div>
        </details>
        <br>
    </details>
    <br/>
    <details>
      <summary style="font-weight: 700; text-transform: uppercase; letter-spacing: 1px; opacity: 0.8; font-size: 1.0em;">
        Transfer Film
      </summary>
      <br>
      <p>
      <strong>We also print on DASS ART transfer films,</strong>
      <br>
      enquire about pricing: <a :href="'mailto:thepaintedpixel@gmail.com'">
      thepaintedpixel@gmail.com
      </a>
      </p>
      <br>
      <a target="_blank" href="https://dassart.com/"><img src="../assets/dass.png" /></a>
      <br/>
      <br>
    </details>

    <br/>

          <details>
      <summary style="font-weight: 700; text-transform: uppercase; letter-spacing: 1px; opacity: 0.85; font-size: 1.0em; color: rgba(242, 157, 75, 1.0);">Request Printing</summary>
      <h5>Please include in the email below:</h5>
      <p>
      Print size (size of printed image)
      <br>
      Paper size (size of printed image + borders)
      <br>
      Quantity of prints per image/size
      <br>
      Media (canvas, fine art paper etc.)
      <br>
      </p>
      <h5>For larger files, let us know, and we'll send you an upload link.</h5>
      <a target="_blank" style="
      background-color: transparent; 
      border: 1px solid #546476;
      color: #2c3e50; 
      height: 40px; 
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1px; 
      line-height: 38px; 
      padding: 0 10px; 
      border-radius: 8px; 
      font-weight: 500; 
      font-size: 14px; 
      cursor: pointer; display: 
      inline-block;"
      :href="'mailto:thepaintedpixel@gmail.com'">Email Printing Request
      </a>
      <br/>
      </details>

  </div>
  <br>
  <br>
</template>

<script>
export default {
  data() {
    return {
      chooseOption: "",
    };
  },
};
</script>

<style scoped>
table{
  margin-left: auto;
  margin-right:auto;
}

th{
  padding-left: 10px;
  padding-right: 10px;
}

.sqin{
  opacity: 0.5;
}

a {
  color: #2c3e50;
  text-decoration: none;
  opacity: 0.7;
  }

a:hover{
  opacity: 1;
  }



</style>